import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { InjectionToken } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { Store } from '@ngrx/store';
import { clearUser, setUser } from '../store/actions/auth.actions';
import { loadSettings } from '../store/actions/settings.actions';

export enum AuthScheme {
    PASSWORD,
    OAUTH2,
}

export const authScheme = {
    scheme: AuthScheme.PASSWORD,
};

export const OAUTH2_ENABLED = new InjectionToken<boolean>('SPINEAI_OAUTH2_ENABLED');

export function initializeAppFactory(
    api: ApiService,
    tokenService: NbTokenService,
    store: Store,
): () => Observable<any> {
    return () => {
        store.dispatch(loadSettings());
        if (authScheme.scheme === AuthScheme.PASSWORD) {
            return of(true);
        }
        return api.getLoggedInUser().pipe(
            switchMap(user => {
                if (user?.access_token) {
                    store.dispatch(setUser({ user }));
                    return tokenService.set(new NbAuthJWTToken(user.access_token, 'oauth2'));
                }
                store.dispatch(clearUser());
                return tokenService.clear();
            }),
            catchError(err => {
                console.error(err);
                // return of(true);
                store.dispatch(clearUser());
                return tokenService.clear();
            }),
        );
    };
}
