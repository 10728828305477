import { createAction, props } from '@ngrx/store';
import { LogoImage } from '../../domain';

export enum SettingsActionType {
    Reset = '[SETTINGS] Reset',
    Loading = '[SETTINGS] Loading',
    LoadSuccess = '[SETTINGS] Loaded Success',
    LoadFailure = '[SETTINGS] Loaded Failure',
}

export const clearSettings = createAction(SettingsActionType.Reset, (prop: string = 'Reset') => ({
    prop,
}));

export const loadSettings = createAction(
    SettingsActionType.Loading,
    (prop: string = 'Loading') => ({
        prop,
    }),
);

export const loadSettingsSuccess = createAction(
    SettingsActionType.LoadSuccess,
    props<{ response: LogoImage }>(),
);

export const loadSettingsFailure = createAction(
    SettingsActionType.LoadFailure,
    props<{ error: any }>(),
);
