import { createAction, props } from '@ngrx/store';
import { Oauth2User } from '../../domain';

export enum AuthActionType {
    DoNothing = '[AUTH] Do Nothing', // figure out how to not use this as a placeholder for the effect
    ClearUser = '[AUTH] Clear User',
    SetUser = '[AUTH] Set User',
}

export const doNothing = createAction(AuthActionType.DoNothing, (prop: string = 'Nothing') => ({
    prop,
}));

export const clearUser = createAction(AuthActionType.ClearUser, (prop: string = 'Clear') => ({
    prop,
}));

export const setUser = createAction(AuthActionType.SetUser, props<{ user: Oauth2User }>());
