import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AuthScheme, authScheme } from './app/init/initializer';

if (environment.production) {
    enableProdMode();
}

fetch(`${environment.oauth_url}/oauth2/enabled`)
    .then(async res => {
        const enabled: boolean = await res.json();
        authScheme.scheme = enabled ? AuthScheme.OAUTH2 : AuthScheme.PASSWORD;
        console.log('oauth2 load complete', { enabled });
    })
    .catch(err => console.error('error loading oauth status', err))
    .finally(async () => {
        const m = await import('./app/app.module');
        platformBrowserDynamic()
            .bootstrapModule(m.AppModule)
            .catch(err => console.error('error initializing app', err));
    });
